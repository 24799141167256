import cover from "./onTruck.png";
import bg from "./onWhite.png";

const getCustomThemeInitialState = (theme) => ({
  cardBgColor: "#f2f2f2" || (theme && theme.palette.action.disabled),
  cardBorderRadius: 8,
  avatarDimension: 150,
  primaryColor: "#232f3e" || (theme && theme.palette.primary.light),
  secondaryColor: "#ff9900" || (theme && theme.palette.secondary.light),
  borderRadius: 50,
  cardShadow: 6,
  contentAlignment: "right",
  ctaIcons: {
    borderWidth: 2,
    borderColor: theme && theme.palette.success.dark,
    color: theme && theme.palette.common.white,
    size: 65,
  },
  cover: {
    image: cover,
    attachment: "scroll",
    repeat: "no-repeat",
  },
  avatar: {
    image: bg,
  },
});

export default getCustomThemeInitialState;
