import { collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { PropTypes } from "prop-types";
import { createContext, useContext, useMemo, useState } from "react";
import { useMicrospecDynamicUIContext } from "../../../MicrospecDynamicUI/GlobalProvider";

const BusinessCardsContext = createContext();

const getCards = async (setCards, firestore, storage, userProfile) => {
  const usersCollection = firestore.user.collectionName;
  const userId = userProfile.attributes.uid;
  const snapshot = getDocs(
    collection(firestore.instance, `${usersCollection}/${userId}/cards`)
  );
  const cards = await snapshot
    .then((cardsDocs) => {
      if (cardsDocs.empty) {
        return [];
      }
      const cardsDataWithImgsPromises = cardsDocs.docs.map(async (card) => {
        const cardRefPath = `${userId}/${card.id}`;
        const getStorageRef = (suffix) =>
          ref(storage.instance, `${cardRefPath}/${suffix}`);
        const cardData = { ...card.data(), id: card.id };
        // coverImg
        await getDownloadURL(getStorageRef("cover"))
          .then((res) => {
            if (res) {
              cardData.customTheme = {
                ...cardData.customTheme,
                cover: {
                  ...cardData.customTheme.cover,
                  image: res,
                },
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });

        // avatarImg
        await getDownloadURL(getStorageRef("avatar"))
          .then((res) => {
            if (res) {
              cardData.customTheme = {
                ...cardData.customTheme,
                avatar: {
                  ...cardData.customTheme.avatar,
                  image: res,
                },
              };
            }
          })
          .catch((err) => {
            console.log(err);
          });

        return cardData;
      });
      return Promise.all(cardsDataWithImgsPromises)
        .then((res) => res)
        .catch((err) => {
          console.log("Promise all error: ", err);
          return [];
        });
    })
    .catch((err) => {
      console.log(err);
      return [];
    });

  // const cards = snapshot.docs.map((value) => value.data());
  // Uncomment next line if you would like to test things locally
  // const data = await getData('/static/cards/mock.json');
  setCards(cards);
  return cards;
};

export const BusinessCardsProvider = ({ children }) => {
  BusinessCardsProvider.propTypes = {
    children: PropTypes.any,
  };

  const { cloudProvider } = useMicrospecDynamicUIContext();
  const { firestore, userProfile, storage } = cloudProvider;
  const [businessCards, setBusinessCards] = useState();
  // console.log(businessCards);
  const memoBusinessDetails = useMemo(
    () => ({
      businessCards,
      setBusinessCards,
    }),
    [businessCards, setBusinessCards]
  );
  useMemo(() => {
    if (!businessCards && userProfile) {
      getCards(setBusinessCards, firestore, storage, userProfile);
    }
  }, [businessCards, firestore, userProfile]);

  return (
    <BusinessCardsContext.Provider value={memoBusinessDetails}>
      {children}
    </BusinessCardsContext.Provider>
  );
};
export const useBusinessCards = () => useContext(BusinessCardsContext);
