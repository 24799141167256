import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { BusinessCardProvider } from "../UIConsumer/hooks/useBusinessCardDetails/useBusinessCardDetails";
import { BusinessCardsProvider } from "../UIConsumer/hooks/useBusinessCards/useBusinessCards";
import Loader from "./Components/Loader";
import OfflineDialog from "./Components/OfflineDialog";
import ScrollToTop from "./Components/ScrollToTop";
import InternalMicrospecDynamicUIProvider from "./GlobalProvider";
import ApplicationRoutes from "./Helpers/Routes";
import MicrospecThemeProvider from "./Themes";

const MicrospecDynamicUI = ({ config }) => {
  return (
    <BrowserRouter>
      <InternalMicrospecDynamicUIProvider config={config}>
        <MicrospecThemeProvider config={config}>
          <Suspense fallback={<Loader />}>
            <ScrollToTop />
            <OfflineDialog config={config} />
            <BusinessCardsProvider>
              <BusinessCardProvider>
                <ApplicationRoutes config={config} />
              </BusinessCardProvider>
            </BusinessCardsProvider>
          </Suspense>
        </MicrospecThemeProvider>
      </InternalMicrospecDynamicUIProvider>
    </BrowserRouter>
  );
};

export default MicrospecDynamicUI;
