import Checkout from "./components/Checkout/Checkout";
import BusinessCardsLandingPage from "./pages/BusinessCardLandingPage/BusinessCardLandingPage";

const config = {
  application: {
    name: "Flow Logisti - פלו לוגיסטי",
    logo: "/static/images/flow-64-64.png",
    auth: {
      enableRegister: true,
      rolesAssigned: [
        "business"
      ]
    },
    commonConfig: {
      showOfflineDialog: false,
    },
    theme: {
      mode: "light",
      primary: {
        main: "#232f3e"
      },
      secondary: {
        main: "#146eb4"
      },
    }
  },
  pages: [
    {
      path: '/',
      spec: {
        type: "CompositeSections",
        sections: [
          {
            type: "Navbar",
            spec: {
              logoSource: process.env.PUBLIC_URL + "/static/images/flow-64-64.png",
              backgroundColor: "#121212",
              navLinks: [
                {
                  id: "home",
                  title: "ראשי",
                  href: "/"
                },
                {
                  href: "/login?redirectTo=/business",
                  title: "כניסה",
                  id: "login",
                },
              ]
            }
          },
          {
            type: "SectionsDivider",
          },
          {
            type: "HeroSectionWithScrollTrigger",
            spec: {
              backgroundSource: process.env.PUBLIC_URL + "/static/images/logisti_black_background.jpg",
              mainTitleText: "תצטרף אלינו היום!",
              mainTitleColor: "#ffffff",
              secondaryTitleText: "ותהנה ממגוון מיוחד של פתרונות ושירותים עסקיים ולגוסטיים",
              secondaryTitleColor: "#ffffff",
              actionButtonText: "הצטרף",
              actionButtonTextColor: "#000000",
              actionButtonBackground: "#f2f2f2",
              bottomText: "גלול למטה",
              bottomTextColor: "#f2f2f2"
            }
          },
          {
            type: "SectionsDivider"
          },
          {
            type: "Statistics",
            spec: {
              statistics: [
                {
                  title: "משתמשים פעילים",
                  value: 200,
                  prefix: "",
                  suffix: "+",
                },
                {
                  title: "שירותים מתקדמים",
                  value: "12",
                  prefix: "",
                  suffix: "",
                },
                {
                  title: "אינטראקציות יומית",
                  value: "100",
                  prefix: "",
                  suffix: "K+",
                },
              ],
              titleTextColor: "#ff9900",
              valuePrefixSuffixTextColor: "#ffffff",
            }
          },
          {
            type: "SectionsDivider"
          },
          {
            type: "ContactForm",
            spec: {
              formIdentifier: "landing-page-inquiries",
              mainTitleText: "השאר לנו הודעה!",
              mainTitleColor: "#ffffff",
              secondaryTitleText: "יש לך שאלות או בקשה מיוחדת? דבר איתנו.",
              secondaryTitleColor: "#ffffff",
              textBeforeSubmitButton: "לאחר שליחת ההודעה נחזור אליך בהקדם האפשרי",
              submitButtonColor: "#f2f2f2",
              submitButtonText: "שלח",
              clearButtonColor: "#f2f2f2",
              clearButtonText: "נקה",
              successMessage: "תודה שיצרתם קשר! אנו דואגים לחזור בהקדם האפשרי",
              failureMessage: "משהו השתבש! נא לבצע טעינה מחדש לעמוד ולנסות שוב.",
              inputs: [
                {
                  name: "full_name",
                  type: "text",
                  placeholder: "שם פרטי ושם משפחה",
                  label: "שם מלא",
                  required: true,
                },
                {
                  name: "user_email",
                  type: "email",
                  placeholder: "john@email.com",
                  errorMessage: "נא להזין כתובת דואר אלקטרוני תקינה",
                  label: 'דוא"ל',
                  required: true,
                },
                {
                  name: "user_phone",
                  type: "text",
                  placeholder: "0520001234",
                  errorMessage: "נא להזין מספר טלפון תקין",
                  label: "*מספר טלפון",
                  required: true,
                },
                {
                  name: "message",
                  type: "textarea",
                  placeholder: "ספר לנו",
                  label: "ההודעה שלך",
                  rows: 5,
                  required: true,
                },
              ]
            }
          },
          {
            type: "SectionsDivider",
            spec: {
              baseBackground: "#3f3e45",
              dividerBackground: "#3f3e45",
              width: "50%"
            }
          },
          {
            type: "Footer",
            spec: {
              backgroundColor: "#000000",
              logoSource: process.env.PUBLIC_URL + "/static/images/logisti_on_black.svg",
              sloganText: "פתרונות עסקיים מותאמות לכל עסק",
              sloganTextColor: "#f2f2f2",
              startMainText: "כל הזכויות שמורים",
              startSecondaryText: "❤️ By FlowLogisti with"
            }
          }

        ]
      }
    },
    {
      path: '/business-cards',
      name: {
        type: "Literal",
        text: "כרטיסי ביקור"
      },
      spec: {
        type: "Simple",
        object: <BusinessCardsLandingPage />
      }
    },
    {
      path: `/:cardId/visit`,
      name: {
        type: "Literal",
        text: "תורים",
      },
      spec: {
        object: <Checkout />,
        type: "Simple"
      },
    },
  ],
  dashboards: [
    {
      path: "/business",
      name: {
        type: "Literal",
        text: "Businesses",
      },
      roles: ["business"],
      items: [
        // {
        //   path: "dashboard",
        //   name: {
        //     type: "Localized",
        //     text: "business.dashboard",
        //   },
        //   icon: <DashboardOutlined />,
        //   hide: false,
        //   spec: {
        //     type: "Simple",
        //     object: <h1></h1>,
        //   },
        // },
        // {
        //   path: "cards",
        //   hide: false,
        //   name: {
        //     type: "Localized",
        //     text: "business.cards",
        //   },
        //   icon: <BusinessOutlined />,
        //   spec: {
        //     type: "Simple",
        //     object: <BusinessCards />,
        //     fabs: [
        //       {
        //         icon: <AddOutlined />,
        //         color: "primary",
        //         onClick: () => {
        //           window.location.href = "/business/cards?new=true";
        //         },
        //       },
        //     ],
        //   },
        // },
        // {
        //   path: "card/:id/edit",
        //   hide: true,
        //   name: {
        //     type: "Localized",
        //     text: "business.cardEditor",
        //   },
        //   icon: <BusinessOutlined />,
        //   spec: {
        //     type: "Simple",
        //     object: <BusinessCardEditor />,
        //   },
        // },
      ],
    },
  ],
  languages: {
    default: "he",
    list: [
      // {code: 'ar', name: 'العربية'},
      { code: "he", name: "עברית" },
      // {code: 'en', name: 'English'},
    ],
  },
};

export default config;
